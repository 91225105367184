<template>
	<van-list
		v-model="loading"
		:finished="finished"
		finished-text="已加载全部"
		@load="loadData"
		>
		<van-cell is-link v-for="(item, key) in lists" :key="key" :title="item.total" :label="item.date" @click="goInfo(item.id)">
			<template #right-icon>
				<span style="color: grey;" v-if="item.status == 1">待转账</span>
				<span style="color: steelblue;" v-if="item.status == 2">已成功</span>
				<span style="color: red;" v-if="item.status == 3">已取消</span>
				<span style="color: blue;" v-if="item.status == 4">已上传回单</span>
			</template>
		</van-cell>
	</van-list>
</template>

<script>
	export default {
		name: 'deposit_history',
		data() {
			return {
				loading: false,
				finished: false,
				
				lists: [],
				
				formItem: {
					page: 1,
					limit: 10,
					token: ""
				},
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token
				this.loadData()
			}
		},
		methods: {
			loadData() {
				this.loading = true
				
				this.$axios({
					method: 'post',
					url: 'cash_deposit/history', 
					data: this.formItem
				}).then ((res) => {
					
					this.loading = false
					
					if (res.success) {
						if (res.data.length != 0) {
							this.lists = this.lists.concat(res.data)

							if (res.data.length < this.formItem.limit) {
								this.finished = true
							} else {
								this.formItem.page++
							}
						} else {
							this.finished = true
						}
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			goInfo(id) {
				this.$router.push('/deposit/info?id=' + id + '&token=' + this.formItem.token) 
			}
		}
	}
</script>